import React, { useEffect, useRef } from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";
import qs from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getSignedUrl } from "../store/action";
import Header from "./componenet/Header";
import "./styles.css";

const useStyle = makeStyles({
  wrapper: {
    width: "100%",
    height: "100vh",
  },
  wordWrapper: {
    height: "97vh",
  },
});

const ImageViewer = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const printRef = useRef(null);
  const object = qs.parse(history.location.search);
  const fileId = object.fileId;
  const { loading, error, signedUrl } = useSelector((state) => state.link);

  useEffect(() => {
    dispatch(getSignedUrl(fileId));
  }, []);

  const classes = useStyle();
  return (
    <div className={`${classes.wrapper}`}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Header url={signedUrl} />
          <div className={classes.wordWrapper} reference={printRef}>
            <img src={signedUrl} width="auto" height="90%" />
          </div>
        </>
      )}
    </div>
  );
};

export default ImageViewer;
