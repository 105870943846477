import React from "react";
import { makeStyles } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

import logo from "../assets/scannergo.png";

const useStyle = makeStyles((theme) => {
  return {
    footerBox: {
      position: "fixed",
      left: 0,
      bottom: 0,
      width: "100%",
      backgroundColor: "#202938",
      color: "#fff",
      padding: "5px",
    },
    footerWrapper: {
      margin: "10px 20px",
    },
    avatarIcon: {
      width: "30px",
      height: "30px",
      marginLeft: "25px",
    },
    footerHeader: {
      color: "#60C89A",
      margin: "2px",
    },
  };
});

const Footer = (props) => {
  const classes = useStyle();
  return (
    <div className={classes.footerBox}>
      <div className={classes.footerWrapper}>
        <a href="https://scannergo.net" target="__blank">
          <Avatar
            src={logo}
            className={`${classes.large} ${classes.avatarIcon}`}
          />
          Scanner Go{" "}
        </a>
        <span>
          An ultimate scanning app that turns your phone into a document
          scanner. Allows you to scan, convert, edit & share documents
          hassle-free. Download the app now
        </span>
        {/* <h3 className={classes.footerHeader}>Scanner Go</h3> */}
      </div>
    </div>
  );
};

export default Footer;
