import React from "react";
import notFoundIcon from "../assets/notfound.svg";
import { makeStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
	header: {
		textAlign: "center",
		margin: "40px",
		maxWidth: "90%",
	},
	headerText: {
		color: "#606060",
		fontSize: "30px",
		textAlign: "center",
	},
});

const ErrorPage = () => {
	const classes = useStyles();
	return (
		<div>
			<div className={classes.header}>
				<h2 className={classes.headerText}>Sorry Page Not Found.!</h2>
				<img style={{ maxWidth: "90%" }} src={notFoundIcon} />
				<h3>File not found, Please contact the owner </h3>
			</div>
		</div>
	);
};

export default ErrorPage;
