import { createSlice } from "@reduxjs/toolkit";

const linkSlice = createSlice({
  name: "agency",
  initialState: {
    loading: false,
    error: "",
    signedUrl: "",
    fileName: "",
  },
  reducers: {
    setUIState(state, action) {
      state.loading = action.payload.loading;
      state.error = action.payload.error;
    },
    setLinkState(state, action) {
      state.signedUrl = action.payload.signedUrl;
      state.fileName = action.payload.fileName;
    },
  },
});

export const linkAction = linkSlice.actions;

export default linkSlice;
