import { configureStore } from "@reduxjs/toolkit";

import linkSlice from "./slice";

const store = configureStore({
  reducer: {
    link: linkSlice.reducer,
  },
});

export default store;
