import { linkAction } from "./slice";
import axios from "../axios";

export const getSignedUrl = (fileId) => {
  return async (dispatch) => {
    const getLink = async () => {
      const response = await axios.post(
        "/v1/file-share/get-signedUrl-tinyUrl",
        {
          fileId,
        }
      );
      return response.data;
    };
    try {
      dispatch(linkAction.setUIState({ loading: true, error: "" }));
      const response = await getLink();
      dispatch(
        linkAction.setLinkState({
          signedUrl: response.signedUrl,
          fileName: response.fileName,
        })
      );
      dispatch(linkAction.setUIState({ loading: false, error: "" }));
    } catch (error) {
      console.log(error);
      dispatch(linkAction.setUIState({ error: "URL expired" }));
    }
  };
};
