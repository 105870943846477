import React, { useEffect, useState } from "react";
import { useLocation, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSignedUrl } from "../store/action";
import { CircularProgress } from "@material-ui/core";

const HomePage = () => {
  const search = useLocation().search;
  const qs = new URLSearchParams(search);
  const fileId = qs.get("fileId");

  const dispatch = useDispatch();
  const { fileName, error, loading } = useSelector((state) => state.link);

  useEffect(() => {
    dispatch(getSignedUrl(fileId));
  }, [fileId]);

  const notMatch = (fileName) => {
    let extension = [
      ".doc",
      ".docx",
      ".ppt",
      ".pptx",
      ".jpg",
      ".jpeg",
      ".png",
      ".jpg",
      ".svg",
      ".pdf",
      ".mp4",
      ".xlsx",
      ".xls",
      ".csv",
    ];
    const ext = fileName.substring(fileName.lastIndexOf("."));

    if (extension.includes(ext)) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      {loading ? (
        <CircularProgress />
      ) : (
        <div>
          {fileName &&
          (fileName.endsWith("jpg") ||
            fileName.endsWith("png") ||
            fileName.endsWith("jpeg") ||
            fileName.endsWith("svg")) ? (
            <Redirect to={`/image-viewer?fileId=${fileId}`} />
          ) : null}

          {fileName &&
          (fileName.endsWith("doc") || fileName.endsWith("docx")) ? (
            <Redirect to={`/docx-reader?fileId=${fileId}`} />
          ) : null}

          {fileName &&
          (fileName.endsWith("ppt") || fileName.endsWith("pptx")) ? (
            <Redirect to={`/ppt-reader?fileId=${fileId}`} />
          ) : null}

          {fileName && fileName.endsWith("pdf") ? (
            <Redirect to={`/pdfviewer?fileId=${fileId}`} />
          ) : null}

          {fileName && fileName.endsWith("mp4") ? (
            <Redirect to={`/video-player?fileId=${fileId}`} />
          ) : null}

          {fileName &&
          (fileName.endsWith("xls") || fileName.endsWith("xlsx")) ? (
            <Redirect to={`/xlsx-reader?fileId=${fileId}`} />
          ) : null}

          {fileName && fileName.endsWith("csv") ? (
            <Redirect to={`/csv-reader?fileId=${fileId}`} />
          ) : null}

          {fileName && !notMatch(fileName) ? (
            <Redirect to={`/download?fileId=${fileId}`} />
          ) : null}

          {error ? <Redirect to={`/not-found`} /> : null}
        </div>
      )}
    </div>
  );
};

export default HomePage;
