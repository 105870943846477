import "./App.css";
import React from "react";
import Footer from "./component/Footer";
import { Route } from "react-router-dom";
import HomePage from "./pages/Home";
import VideoPlayer from "./Fileviewer/VideoPlayer";
import Pdfviewer from "./Fileviewer/PdfReader";
import WordFile from "./Fileviewer/DocxReader";
import CsvReader from "./Fileviewer/CSVreader";
import XlsxReader from "./Fileviewer/XlsxReader";
import PPTReader from "./Fileviewer/PPTReader";
import ImageViewer from "./Fileviewer/ImageViewer";
import Download from "./Fileviewer/Download";
import NotFound from "../src/Fileviewer/404";

function App() {
  return (
    <div className="App">
      <Route path="/public-link" exact component={HomePage} />
      <Route exact path="/image-viewer" component={ImageViewer} />
      <Route exact path="/docx-reader" component={WordFile} />
      <Route path="/pdfviewer" exact component={Pdfviewer} />
      <Route exact path="/video-player" component={VideoPlayer} />
      <Route exact path="/csv-reader" component={CsvReader} />
      <Route exact path="/ppt-reader" component={PPTReader} />
      <Route exact path="/xlsx-reader" component={XlsxReader} />
      <Route exact path="/download" component={Download} />
      <Route path="/not-found" component={NotFound} />
      <Footer />
    </div>
  );
}

export default App;
