import React, { useEffect, useRef } from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";
import qs from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getSignedUrl } from "../store/action";
import Header from "./componenet/Header";

const useStyle = makeStyles({
  wrapper: {
    width: "100%",
    overflowx: "auto",
  },
  wordWrapper: {
    height: "97vh",
  },
});

const CsvReader = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const printRef = useRef(null);
  const object = qs.parse(history.location.search);
  const fileId = object.fileId;
  const { loading, error, signedUrl } = useSelector((state) => state.link);

  useEffect(() => {
    dispatch(getSignedUrl(fileId));
  }, []);

  const classes = useStyle();
  return (
    <div className={`${classes.wrapper}`}>
      <Header url={signedUrl} reference={printRef} />
      {loading ? (
        <CircularProgress />
      ) : (
        <div className={classes.wordWrapper}>
          <iframe
            ref={printRef}
            style={{ width: "100%", height: "100%" }}
            src={`https://docs.google.com/gview?url=${signedUrl}&embedded=true`}
            frameborder="0"
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default CsvReader;
