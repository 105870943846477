import React, { useEffect, useRef } from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";
import qs from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getSignedUrl } from "../store/action";
import Header from "./componenet/Header";

function PdfViewer(prop) {
  const dispatch = useDispatch();
  const history = useHistory();
  const printRef = useRef(null);
  const object = qs.parse(history.location.search);
  const fileId = object.fileId;
  const { loading, error, signedUrl } = useSelector((state) => state.link);

  useEffect(() => {
    dispatch(getSignedUrl(fileId));
  }, []);

  return (
    <div className="pdf-viewer">
      {loading ? (
        <CircularProgress />
      ) : (
        <div>
          <div id="print">
            <iframe
              ref={printRef}
              id="frame"
              src={signedUrl}
              // src={`https://docs.google.com/gview?url=${signedUrl}&embedded=true`}
              style={{ width: "100%", height: "100vh" }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default PdfViewer;
