import React, { useEffect, useRef } from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";
import qs from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getSignedUrl } from "../store/action";
import Header from "./componenet/Header";

const useStyles = makeStyles({
  aBox: {
    width: "20%",
    height: "200px",
    margin: "200px auto",
  },
  anchor: {
    fontSize: "1.4em",
  },
});

function Download(prop) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const printRef = useRef(null);
  const object = qs.parse(history.location.search);
  const fileId = object.fileId;
  const { loading, error, signedUrl } = useSelector((state) => state.link);

  useEffect(() => {
    dispatch(getSignedUrl(fileId));
  }, []);

  return (
    <div className="pdf-viewer">
      {loading ? (
        <CircularProgress />
      ) : (
        <div>
          <div id="print" className={classes.aBox}>
            <a className={classes.anchor} type="button" href={signedUrl}>
              Download
            </a>
          </div>
        </div>
      )}
    </div>
  );
}

export default Download;
