import React, { useState } from "react";
import GetAppIcon from "@material-ui/icons/GetApp";
import PrintIcon from "@material-ui/icons/PrintOutlined";
import { makeStyles } from "@material-ui/core";
import "./styles.css";

const useStyle = makeStyles({
	icon: {
		"&:hover": {
			cursor: "pointer",
		},
	},
});

const printDoc = () => {
	window.frames[0].focus();
	window.frames[0].print();
};

const Header = (props) => {
	const classes = useStyle();

	return (
		<div className={`header`}>
			<a href={props.url} download>
				<GetAppIcon className={classes.icon} color="primary" fontSize="large" />
			</a>
		</div>
	);
};

export default Header;
