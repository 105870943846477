import React, { useEffect, useRef } from "react";
import { CircularProgress, makeStyles } from "@material-ui/core";
import qs from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getSignedUrl } from "../store/action";
import Header from "./componenet/Header";

const VideoPlayer = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const printRef = useRef(null);
  const object = qs.parse(history.location.search);
  const fileId = object.fileId;
  const { loading, error, signedUrl } = useSelector((state) => state.link);

  useEffect(() => {
    dispatch(getSignedUrl(fileId));
  }, []);

  return (
    <div>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Header url={signedUrl} />
          <video style={{ width: "100%", height: "100vh" }} controls>
            <source src={signedUrl} type="video/mp4" />
          </video>
        </>
      )}
    </div>
  );
};

export default VideoPlayer;
